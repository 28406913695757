import { UserButtons, UserInfo } from '@/components'
import { Advertisements } from '@/components'

import { useAuth } from '@/hooks'

import styles from './User.module.scss'

export const User = (): JSX.Element => {
	const { role } = useAuth()

	return (
		<div className={styles.wrapper}>
			<div className={styles.info}>
				<UserButtons role={role} />
				<UserInfo role={role} />
			</div>
			<Advertisements />
		</div>
	)
}

export default User
