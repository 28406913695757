import { createColumnHelper } from '@tanstack/react-table'
import { Link } from 'react-router-dom'

import { Button } from '@/components'

import { IUser } from '@/types'

import { TypeModal } from './List'
import styles from './List.module.scss'

const columnHelper = createColumnHelper<
	IUser & { role: string; btns?: any; isBannedStr: string }
>()

export const userColumns = (
	openModal: (type: TypeModal, user: IUser) => void
) => [
	columnHelper.accessor('userName', {
		size: 'auto' as unknown as number,
		header: () => <span>ФИО</span>,
	}),
	columnHelper.accessor('email', {
		size: 'auto' as unknown as number,
		header: () => <span>Почта</span>,
		cell: ({ row }) => (
			<Link to={`mailto:${row.original.email}`}>{row.original.email}</Link>
		),
	}),
	columnHelper.accessor('phoneNumber', {
		size: 'auto' as unknown as number,
		header: () => <span>Номер телефона</span>,
		cell: ({ row }) => (
			<Link to={`tel:${row.original.phoneNumber}`}>
				{row.original.phoneNumber}
			</Link>
		),
	}),
	columnHelper.accessor('role', {
		size: 'auto' as unknown as number,
		header: () => <span>Роль</span>,
	}),
	columnHelper.accessor('isBannedStr', {
		size: 'auto' as unknown as number,
		header: () => <span>Статус</span>,
	}),
	columnHelper.accessor('btns', {
		size: 'auto' as unknown as number,
		header: () => <span>Управление</span>,
		cell: ({ row }) => (
			<div className={styles.btns}>
				<Button onClick={() => openModal('choice', row.original)}>
					Управление
				</Button>
			</div>
		),
	}),
]
