import { createColumnHelper } from '@tanstack/react-table'
import { Link } from 'react-router-dom'

import { Button } from '@/components'

import { RequestsService } from '@/services'

import { IInfo } from '@/types'

import styles from './List.module.scss'

const columnHelper = createColumnHelper<
	IInfo & { btns?: any; startDateStr: string; endDateStr: string }
>()

export const userColumns = (goTo: (url: IInfo) => void, update: () => void) => [
	columnHelper.accessor('identifier', {
		size: 'auto' as unknown as number,
		header: () => <span>Номер</span>,
	}),
	columnHelper.accessor('startDateStr', {
		size: 'auto' as unknown as number,
		header: () => <span>Дата начала</span>,
	}),
	columnHelper.accessor('endDateStr', {
		size: 'auto' as unknown as number,
		header: () => <span>Дата завершения</span>,
	}),
	columnHelper.accessor('type', {
		size: 'auto' as unknown as number,
		header: () => <span>Тип заявки</span>,
	}),
	columnHelper.accessor('status.name', {
		size: 'auto' as unknown as number,
		header: () => <span>Статус</span>,
		cell: ({ row }) =>
			row.original.status.name === 'Принято' ? (
				<p className="text-green-500 font-bold">{row.original.status.name}</p>
			) : (
				<p>{row.original.status.name}</p>
			),
	}),
	columnHelper.accessor('mainContactName', {
		size: 'auto' as unknown as number,
		header: () => <span>ФИО сотрудника</span>,
	}),
	columnHelper.accessor('mainContactPhoneNumber', {
		size: 'auto' as unknown as number,
		header: () => <span>Номер телефона сотрудник</span>,
		cell: ({ row }) => (
			<Link to={`tel:${row.original.mainContactPhoneNumber}`}>
				{row.original.mainContactPhoneNumber}
			</Link>
		),
	}),
	columnHelper.accessor('renterComment', {
		size: 'auto' as unknown as number,
		header: () => <span>Комментарий</span>,
	}),
	columnHelper.accessor('btns', {
		size: 'auto' as unknown as number,
		header: () => <span>Управление</span>,
		cell: ({ row }) => (
			<div className={styles.btns}>
				<Button onClick={() => goTo(row.original)}>Подробнее</Button>
				{row.original.status.name === 'На рассмотрении' && (
					<Button
						appearance="red"
						onClick={async () =>
							await RequestsService.cancel(row.original.id, update)
						}
					>
						Отменить
					</Button>
				)}
			</div>
		),
	}),
]
