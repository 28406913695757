import {
	Column,
	Table as ReactTable,
	SortingState,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table'
import cn from 'clsx'
import { FC, memo, useState } from 'react'

import { Button, Input } from '@/components'

import styles from './Table.module.scss'

interface IProps {
	dataProps: any[]
	columns: any[]
	className?: string
	colVisibility?: any
	click?: (item: any) => void
}

export const Table: FC<IProps> = memo(
	({
		dataProps = [],
		columns,
		className,
		colVisibility,
		click,
	}): JSX.Element => {
		const [sorting, setSorting] = useState<SortingState>([])

		const table = useReactTable({
			data: dataProps,
			columns,
			state: {
				sorting,
				columnVisibility: colVisibility,
			},
			initialState: {
				pagination: {
					pageSize: 20,
				},
			},
			getCoreRowModel: getCoreRowModel(),
			getPaginationRowModel: getPaginationRowModel(),
			getFilteredRowModel: getFilteredRowModel(),
			onSortingChange: setSorting,
			getSortedRowModel: getSortedRowModel(),
		})

		return (
			<>
				<table className={cn(styles.table, className)}>
					<thead>
						{table.getHeaderGroups().map((headerGroup) => (
							<tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									return (
										<th
											{...{
												key: header.id,
												colSpan: header.colSpan,
											}}
										>
											{header.isPlaceholder ? null : (
												<div>
													<p
														{...{
															className: header.column.getCanSort()
																? 'cursor-pointer select-none'
																: '',
															onClick: header.column.getToggleSortingHandler(),
														}}
													>
														{flexRender(
															header.column.columnDef.header,
															header.getContext()
														)}
														{{
															asc: ' 🔼',
															desc: ' 🔽',
														}[header.column.getIsSorted() as string] ?? null}
													</p>
													{header.column.getCanFilter() &&
													header.column.id !== 'btns' ? (
														<div>
															<Filter column={header.column} table={table} />
														</div>
													) : null}
												</div>
											)}
										</th>
									)
								})}
							</tr>
						))}
					</thead>
					<tbody>
						{table.getRowModel().rows.map((row) => (
							<tr
								key={row.id}
								className={cn({
									'cursor-pointer': click,
								})}
								onClick={() => {
									click && click(row.original)
								}}
							>
								{row.getVisibleCells().map((cell) => (
									<td
										{...{
											key: cell.id,
											style: {
												// width: cell.column.getSize(),
												width: 'auto',
											},
										}}
									>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
				{table.getRowModel().rows.length && table.getPageCount() !== 1 ? (
					<>
						<div className="h-2" />
						<div className={styles.pagination}>
							<Button
								appearance="pagination"
								onClick={() => table.setPageIndex(0)}
								disabled={!table.getCanPreviousPage()}
							>
								{'<<'}
							</Button>
							<Button
								appearance="pagination"
								onClick={() => table.previousPage()}
								disabled={!table.getCanPreviousPage()}
							>
								{'<'}
							</Button>
							<span className="flex items-center gap-1">
								<div>Страница</div>
								<strong>
									{table.getState().pagination.pageIndex + 1} из{' '}
									{table.getPageCount()}
								</strong>
							</span>
							<Button
								appearance="pagination"
								onClick={() => table.nextPage()}
								disabled={!table.getCanNextPage()}
							>
								{'>'}
							</Button>
							<Button
								appearance="pagination"
								onClick={() => table.setPageIndex(table.getPageCount() - 1)}
								disabled={!table.getCanNextPage()}
							>
								{'>>'}
							</Button>
						</div>
					</>
				) : null}
			</>
		)
	}
)

function Filter({
	column,
	table,
}: {
	column: Column<any, any>
	table: ReactTable<any>
}) {
	const columnFilterValue = column.getFilterValue()

	return (
		<Input
			type="text"
			className="p-0.5 text-sm"
			value={(columnFilterValue ?? '') as string}
			onChange={(e) => column.setFilterValue(e.target.value)}
			placeholder={`Поиск...`}
		/>
	)
}
