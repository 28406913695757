import { StylesConfig } from 'react-select'

export const selectMyStyles: StylesConfig = {
	control: (styles) => {
		return {
			...styles,
			borderColor: '#999AA5',
			borderWidth: 2,
			borderRadius: '0.375rem',
		}
	},
	option: (styles, { isSelected }) => {
		return {
			...styles,
			backgroundColor: isSelected ? '#09be8b' : '#fff',
			borderColor: '#999AA5',
			color: isSelected ? '#fff' : '#000',
			':hover': {
				backgroundColor: '#09be8b',
				color: '#fff',
			},
		}
	},
}

export const selectMyStylesMinList: StylesConfig = {
	option: (styles, { isSelected }) => {
		return {
			...styles,
			backgroundColor: isSelected ? '#09be8b' : '#fff',
			color: isSelected ? '#fff' : '#000',
			':hover': {
				backgroundColor: '#09be8b',
				color: '#fff',
			},
		}
	},
	menuList: (styles) => {
		return {
			...styles,
			height: '100px !important',
			overflow: 'auto',
		}
	},
}
