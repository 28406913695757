import cn from 'clsx'
import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { MdClose } from 'react-icons/md'
import { UserService } from 'services/user.service'

import { Button, FieldInput, SubHeading } from '@/components'

import styles from './Modal.module.scss'

export const ChangePhoneModal: FC<{
	id: string
	close: () => void
	update: () => void
}> = ({ id, close, update }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<{ newPhone: string }>({
		mode: 'onChange',
	})

	const onSubmit: SubmitHandler<{
		newPhone: string
	}> = async (body) =>
		await UserService.changePhoneAdmin(
			{
				userId: id,
				newPhone: body.newPhone,
			},
			close,
			update
		)

	return (
		<div className={styles.modal}>
			<div className={styles.header}>
				<SubHeading title="Сменить номер" />
				<MdClose size={20} onClick={close} />
			</div>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={cn(styles.body, styles.form)}
			>
				<FieldInput
					placeholder="Номер телефона"
					type="tel"
					{...register('newPhone', {
						required: 'Поле обязательно к заполнению!',
						pattern: {
							value: /(\+7|7|8)+([0-9]){10}/gm, //eslint-disable-line
							message: 'Формат номера телефона +79123456789',
						},
					})}
					error={errors.newPhone}
				/>
				<Button>Сменить</Button>
			</form>
		</div>
	)
}
