import { format } from 'date-fns'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Modal from 'react-modal'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

import {
	Advertisements,
	Button,
	FieldInput,
	FieldSelect,
	Loader,
	Textarea,
	Title,
} from '@/components'

import { useAuth } from '@/hooks'

import {
	DictionariesService,
	EmployeesService,
	RequestsService,
	UserService,
} from '@/services'

import { isDisable, modalStyle } from '@/settings'

import { IAddPromo, ISelect } from '@/types'

import styles from './Form.module.scss'
import { CommentModal } from './modal/CommentModal'

type TAddPromoForm = IAddPromo & {
	counterpartyId?: string
}

export const RequestNewPromo = (): JSX.Element => {
	const navigate = useNavigate()
	const { role } = useAuth()
	const { id } = useParams()
	const { state } = useLocation()

	const [isLoad, setIsLoad] = useState<boolean>(true)
	const [modalIsOpen, setIsOpen] = useState<boolean>(false)
	const [types, setTypes] = useState<ISelect[]>([])

	const [users, setUsers] = useState<ISelect[]>([])
	const [counterparty, setCounterparty] = useState<ISelect[]>()

	const fetch = async (id?: string) => {
		setIsLoad(true)

		const types = await DictionariesService.promoTypes()
		setTypes(types)

		if (role === 'User') {
			const users = await EmployeesService.getUser()
			setUsers(
				users?.map((item) => {
					return {
						label: item.name,
						value: item.id,
					}
				})
			)
		} else if (role === 'Admin' || role === 'Manager' || role === 'Marketer') {
			const data = await UserService.getUserAll()
			setCounterparty(
				data
					.filter((item) => item.counterparty !== null)
					.map((item) => {
						return {
							label: item.counterparty.name,
							value: item.counterparty.id,
						}
					})
			)
			if (state?.req) {
				const users = await UserService.getUserCounterpartyView({
					CounterpartyId: state?.req?.counterparty?.id,
				})
				setUsers(
					users?.employees.map((item) => {
						return {
							label: item.name,
							value: item.id,
						}
					})
				)
			}
		} else {
			const users = await UserService.getUserCounterpartyView({ UserId: id })
			setUsers(
				users?.employees?.map((item: any) => {
					return {
						label: item.name ? item.name : item.userName,
						value: item.id,
					}
				})
			)
		}
		setIsLoad(false)
	}

	const {
		register,
		handleSubmit,
		control,
		watch,
		formState: { errors },
	} = useForm<TAddPromoForm>({
		mode: 'onChange',
		defaultValues: async () => {
			if (id) {
				const data = await RequestsService.getInfo(id)

				!isDisable(state?.req?.status, role)
					? fetch(data?.requestCreator?.id)
					: setIsLoad(false)

				return {
					...data,
					promotersMainContactId: {
						label: data?.promotersMainContact?.name,
						value: data?.promotersMainContact?.id,
					},
					promoTypeId: {
						label: data?.promoType?.name,
						value: data?.promoType?.id,
					},
					counterpartyId: {
						label: data?.counterparty?.name,
						value: data?.counterparty?.id,
					},
					comment: {
						guardComment: data?.guardComment,
						renterComment: data?.renterComment,
					},
				}
			} else {
				fetch()
			}
		},
	})

	const startDateWatch = watch('startDate')
	const counterpartyIdWatch = watch('counterpartyId')
	const guardCommentWatch = watch('guardComment')
	const renterCommentWatch = watch('renterComment')

	const onSubmit: SubmitHandler<any> = async (body) => {
		if (id) {
			await RequestsService.editPromo(
				{
					...body,
					startTime:
						state?.req?.startTime === body.startTime
							? state?.req?.startTime
							: `${body.startTime}:00`,
					endTime:
						state?.req?.endTime === body.endTime
							? state?.req?.endTime
							: `${body.endTime}:00`,
					promoTypeId: body.promoTypeId.value,
					promotersMainContactId: body.promotersMainContactId.value,
					id,
				},
				navigate,
				role
			)
		} else {
			if (role === 'User')
				await RequestsService.addPromo(
					{
						...body,
						startTime: `${body.startTime}:00`,
						endTime: `${body.endTime}:00`,
						promoTypeId: body.promoTypeId.value,
						promotersMainContactId: body.promotersMainContactId.value,
					},
					navigate,
					role
				)
			else
				await RequestsService.addPromo(
					{
						...body,
						startTime: `${body.startTime}:00`,
						endTime: `${body.endTime}:00`,
						counterpartyId: body.counterpartyId.value,
						promoTypeId: body.promoTypeId.value,
						promotersMainContactId: body.promotersMainContactId.value,
					},
					navigate,
					role,
					body.counterpartyId.value
				)
		}
	}

	if (isLoad) {
		return <Loader />
	}

	return (
		<>
			<Title
				title={
					id
						? 'Заявка на проведение промо, фото/видео съемку'
						: 'Новая заявка на проведение промо, фото/видео съемку'
				}
			/>
			<Advertisements position={4} />
			<form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
				<FieldInput
					disabled={isDisable(state?.req?.status, role)}
					placeholder="Название сторонней организации или ФИО физического лица"
					{...register('sideCounterparty')}
					error={errors.sideCounterparty}
				/>
				<FieldInput
					type="date"
					placeholder="Дата начала действия заявки"
					min={format(
						startDateWatch ? new Date(startDateWatch) : new Date(),
						'yyyy-MM-dd'
					)}
					disabled={isDisable(state?.req?.status, role)}
					{...register('startDate', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.startDate}
					required
				/>
				{startDateWatch && (
					<FieldInput
						type="date"
						placeholder="Дата завершения действия заявки"
						disabled={isDisable(state?.req?.status, role)}
						{...register('endDate', {
							required: 'Поле обязательно к заполнению!',
						})}
						error={errors.endDate}
						required
					/>
				)}
				<FieldInput
					disabled={isDisable(state?.req?.status, role)}
					type="time"
					placeholder="Время начала"
					{...register('startTime', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.startTime}
					required
				/>
				<FieldInput
					disabled={isDisable(state?.req?.status, role)}
					type="time"
					placeholder="Время завершения"
					{...register('endTime', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.endTime}
					required
				/>
				<FieldSelect
					id="promoTypeId"
					placeholder="Вид работ"
					control={control}
					options={types}
					rules={{
						required: 'Поле обязательно к заполнению!',
					}}
					error={errors.promoTypeId}
					required
				/>
				<FieldInput
					disabled={isDisable(state?.req?.status, role)}
					type="number"
					min={0}
					placeholder="Количество промоутеров"
					{...register('promotersAmount', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.promotersAmount}
					required
				/>
				<Textarea
					disabled={isDisable(state?.req?.status, role)}
					placeholder="Краткое описание"
					{...register('eventDescription', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.eventDescription}
					required
				/>
				<FieldInput
					disabled={isDisable(state?.req?.status, role)}
					placeholder="Наличие звукового сопровождения (количество колонок)"
					min={0}
					{...register('speakersAmount', {
						required: 'Поле обязательно к заполнению!',
					})}
					type="number"
					error={errors.speakersAmount}
					required
				/>
				<div className={styles.info}>
					<p className={styles.red}>
						При использовании звукового оборудования заявку необходимо
						согласовывать в текстовом виде в офисе Администрации с приложением
						доверенности на лицо, подписывающее заявку!
					</p>
				</div>
				{role === 'Admin' || role === 'Manager' || role === 'Marketer' ? (
					<>
						<FieldSelect
							id="counterpartyId"
							placeholder="Магазин"
							control={control}
							rules={{
								required: 'Поле обязательно к заполнению!',
							}}
							error={errors.counterpartyId}
							options={counterparty}
							changeProps={async (value) => {
								const data = await UserService.getUserCounterpartyView({
									CounterpartyId: value.value.toString(),
								})
								setUsers(
									data?.employees.map((item) => {
										return {
											label: item.name,
											value: item.id,
										}
									})
								)
							}}
							disabled={state?.req}
							required
						/>
						{counterpartyIdWatch && (
							<FieldSelect
								id="promotersMainContactId"
								placeholder="ФИО ответственного за проведение работ"
								control={control}
								rules={{
									required: 'Поле обязательно к заполнению!',
								}}
								options={users}
								error={errors.promotersMainContactId}
								disabled={isDisable(state?.req?.status, role)}
								required
							/>
						)}
					</>
				) : (
					<FieldSelect
						id="promotersMainContactId"
						placeholder="ФИО ответственного за проведение работ"
						control={control}
						rules={{
							required: 'Поле обязательно к заполнению!',
						}}
						options={users}
						error={errors.promotersMainContactId}
						disabled={isDisable(state?.req?.status, role)}
						required
					/>
				)}
				{(role === 'Manager' || role === 'Admin') &&
				state?.req?.mainContactPhoneNumber ? (
					<div>
						<p>
							Номер телефона ответственного за проведение работ:{' '}
							<Link to={`tel:${state?.req?.mainContactPhoneNumber}`}>
								{state?.req?.mainContactPhoneNumber}
							</Link>
						</p>
					</div>
				) : null}
				{role === 'User'
					? renterCommentWatch && (
							<p>Комментарий для арендатора: {renterCommentWatch}</p>
					  )
					: role === 'Admin' || role === 'Manager' || role === 'Marketer'
					  ? (guardCommentWatch || renterCommentWatch) && (
								<div>
									{guardCommentWatch && (
										<p>Комментарий для охраны: {guardCommentWatch}</p>
									)}
									{renterCommentWatch && (
										<p>Комментарий для арендатора: {renterCommentWatch}</p>
									)}
								</div>
					    )
					  : role === 'Guard' &&
					    guardCommentWatch && (
								<p>Комментарий для охраны: {guardCommentWatch}</p>
					    )}
				<div className={styles.info}>
					<p>
						Организатор обязуется своевременно и самостоятельно производить за
						свой счет оплату авторского вознаграждения и предоставлять
						отчетность в РАО и ВОИС в отношении заявленной акции/мероприятия на
						территории ТРК Гулливер, указанных в настоящей заявке. Организатор
						несет полную ответственность перед РАО и ВОИС по оплате
						вознаграждения, причитающегося РАО и ВОИС в рамках осуществления
						звукового вещания в рамках данной акции/мероприятия.
					</p>
				</div>
				{state?.req ? (
					role === 'User' ? (
						state?.req?.status?.name === 'На рассмотрении' ?? (
							<Button>Изменить заявку</Button>
						)
					) : role === 'Admin' || role === 'Manager' || role === 'Marketer' ? (
						<div className={styles.btns}>
							{!isDisable(state?.req?.status, role) ? (
								<Button>Изменить заявку</Button>
							) : (
								<Button
									appearance="red"
									onClick={async (e) => {
										e.preventDefault()
										await RequestsService.decline(id, () => navigate(-1))
									}}
								>
									Аннулировать
								</Button>
							)}
							<Button
								onClick={(e) => {
									e.preventDefault()
									setIsOpen(true)
								}}
							>
								Добавить комментарий
							</Button>
						</div>
					) : null
				) : (
					<Button>Отправить заявку</Button>
				)}
			</form>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => setIsOpen(false)}
				ariaHideApp={false}
				style={modalStyle}
			>
				<CommentModal
					id={id}
					comment={{
						renterComment: renterCommentWatch,
						guardComment: guardCommentWatch,
					}}
					close={() => setIsOpen(false)}
				/>
			</Modal>
		</>
	)
}
