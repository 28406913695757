/* eslint-disable @typescript-eslint/no-unused-expressions */
import { toast } from 'react-toastify'

import { axiosPrivate } from '@/settings'

import {
	IChangeEmail,
	IChangePassword,
	IChangePhone,
	ICreateAdmin,
	ICreateUser,
	IEmployees,
	IUser,
} from '@/types'

const getAdminUrl = (url: string): string => `/users/${url}`

export const UserService = {
	async createAdminTemp(body: ICreateAdmin): Promise<any> {
		try {
			const { data } = await axiosPrivate.post<any>(
				getAdminUrl(`get_info`),
				body
			)

			return data
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async ban(id: string, update: () => void, close?: () => void): Promise<void> {
		try {
			await axiosPrivate.patch<any>(getAdminUrl(`ban_user?UserId=${id}`))
			toast.success('Пользователь заблокирован')
			update()
			close && close()
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async unBan(
		id: string,
		update: () => void,
		close?: () => void
	): Promise<void> {
		try {
			await axiosPrivate.patch<any>(getAdminUrl(`unban_user?UserId=${id}`))
			toast.success('Пользователь разблокирован')
			update()
			close && close()
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async createUser(body: ICreateUser, close: () => void): Promise<void> {
		try {
			await axiosPrivate.post<any>(getAdminUrl('create_user'), body)
			toast.success('Пользователь создан')
			close()
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async createManager(body: ICreateAdmin, close: () => void): Promise<void> {
		try {
			await axiosPrivate.post<any>(getAdminUrl('create_manager'), body)
			toast.success('Пользователь создан')
			close()
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async createMarketer(body: ICreateAdmin, close: () => void): Promise<void> {
		try {
			await axiosPrivate.post<any>(getAdminUrl('create_marketer'), body)
			toast.success('Пользователь создан')
			close()
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async createGuard(body: ICreateAdmin, close: () => void): Promise<void> {
		try {
			await axiosPrivate.post<any>(getAdminUrl('create_guard'), body)
			toast.success('Пользователь создан')
			close()
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async createDispatcher(body: ICreateAdmin, close: () => void): Promise<void> {
		try {
			await axiosPrivate.post<any>(getAdminUrl('create_dispatcher'), body)
			toast.success('Пользователь создан')
			close()
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async deleteUser(
		id: string,
		update: () => void,
		close: () => void
	): Promise<void> {
		try {
			await axiosPrivate.delete<any>(getAdminUrl(`delete_user`), {
				data: id,
				headers: {
					'Content-Type': 'application/json',
				},
			})
			toast.success('Пользователь удален')
			update()
			close()
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async getUserAll(): Promise<IUser[]> {
		try {
			const { data } = await axiosPrivate.get<IUser[]>(getAdminUrl('all'))

			return data
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async getUserAllAdmins(): Promise<IUser[]> {
		try {
			const { data } = await axiosPrivate.get<IUser[]>(
				getAdminUrl('all_admins')
			)

			return data
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async getInfo(): Promise<IUser> {
		try {
			const { data } = await axiosPrivate.get<IUser>(getAdminUrl(`get_info`))
			return data
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async getUserCounterpartyView({
		UserId,
		CounterpartyId,
	}: {
		UserId?: string
		CounterpartyId?: string
	}): Promise<IEmployees> {
		try {
			let params = new URLSearchParams()
			UserId !== undefined ? params.append('UserId', UserId) : null
			CounterpartyId !== undefined
				? params.append('CounterpartyId', CounterpartyId)
				: null
			const { data } = await axiosPrivate.get<IEmployees>(
				getAdminUrl(`get_user_counterparty_view?${params}`)
			)
			return data
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async changePassword(
		body: IChangePassword,
		close: () => void,
		update: () => void
	): Promise<void> {
		try {
			await axiosPrivate.patch<any>(getAdminUrl('change_password'), body)
			toast.success('Данные изменены')
			update()
			close()
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async changeEmail(
		body: IChangeEmail,
		close: () => void,
		update: () => void
	): Promise<void> {
		try {
			await axiosPrivate.patch<any>(getAdminUrl('change_email'), body)

			toast.success('Данные изменены')
			update()
			close()
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async changePhone(
		body: IChangePhone,
		close: () => void,
		update: () => void
	): Promise<void> {
		try {
			await axiosPrivate.patch<any>(getAdminUrl('change_phone'), body)
			toast.success('Данные изменены')
			update()
			close()
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async changePhoneAdmin(
		body: {
			userId: string
			newPhone: string
		},
		close: () => void,
		update: () => void
	): Promise<void> {
		try {
			await axiosPrivate.patch<any>(getAdminUrl('change_user_phone'), body)
			toast.success('Данные изменены')
			update()
			close()
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async changeEmailAdmin(
		body: {
			userId: string
			newEmail: string
		},
		close: () => void,
		update: () => void
	): Promise<void> {
		try {
			await axiosPrivate.patch<any>(getAdminUrl('change_user_email'), body)
			toast.success('Данные изменены')
			update()
			close()
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},
}
