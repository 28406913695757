import { AuthForm, Heading, NumberCheck } from '@/components'

import { useCheckToken } from '../../hooks/useCheckToken'

import styles from './Auth.module.scss'

export const Auth = (): JSX.Element => {
	useCheckToken()

	return (
		<div
			className={styles.wrapper}
			style={{ backgroundImage: 'url(./img/svg/g.svg)' }}
		>
			<div className={styles.main}>
				<div className={styles.heading}>
					<img
						src="./img/png/logo.png"
						width={300}
						height={200}
						alt="Логотип Гулливер"
					/>
					<Heading title="Портал для арендаторов ТРК Гулливер"></Heading>
				</div>
				<div className={styles.forms}>
					<NumberCheck className={styles.requestStatus} />
					<AuthForm className={styles.auth} />
				</div>
			</div>
		</div>
	)
}

export default Auth
