import cn from 'clsx'
import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { MdClose } from 'react-icons/md'
import { UserService } from 'services/user.service'

import { Button, FieldInput, SubHeading } from '@/components'

import styles from './Modal.module.scss'

export const ChangeMailModal: FC<{
	id: string
	close: () => void
	update: () => void
}> = ({ id, close, update }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<{ newEmail: string }>({
		mode: 'onChange',
	})

	const onSubmit: SubmitHandler<{
		newEmail: string
	}> = async (body) =>
		await UserService.changeEmailAdmin(
			{
				userId: id,
				newEmail: body.newEmail,
			},
			close,
			update
		)

	return (
		<div className={styles.modal}>
			<div className={styles.header}>
				<SubHeading title="Сменить почту" />
				<MdClose size={20} onClick={close} />
			</div>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={cn(styles.body, styles.form)}
			>
				<FieldInput
					placeholder="Новая почта"
					type="email"
					{...register('newEmail', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.newEmail}
				/>
				<Button>Сменить</Button>
			</form>
		</div>
	)
}
