import { useAuth } from 'hooks/useAuth'
import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { useLocation, useParams } from 'react-router-dom'

import { Button, Loader, SubHeading, Table, Title } from '@/components'

import { EmployeesService, UserService } from '@/services'

import { modalStyle } from '@/settings'

import { IEmployeeUser } from '@/types'

import styles from './Employees.module.scss'
import { ChoiceModal, EmployeesModal } from './Modal'
import { employeesColumns } from './table.config'

export interface IForm {
	name: string
	surname: string
	lastName: string
	position: string
	phoneNumber: string
}

export type ModalType = 'edit' | 'add' | 'control'

export const Employees = (): JSX.Element => {
	const { role } = useAuth()
	const { state } = useLocation()
	const { shopId } = useParams()

	const [employees, setEmployees] = useState<IEmployeeUser[]>([])
	const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
	const [type, setType] = useState<ModalType>()
	const [isLoad, stIsLoad] = useState<boolean>(true)
	const [employeeProps, setEmployeeProps] = useState<IEmployeeUser>()

	useEffect(() => {
		fetch()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const fetch = async () => {
		stIsLoad(true)
		if (role === 'User') {
			const employees = await EmployeesService.getUser()
			setEmployees(employees)
		} else {
			const data = await UserService.getUserCounterpartyView({
				CounterpartyId: shopId,
			})
			setEmployees(data?.employees)
		}
		stIsLoad(false)
	}

	function openModal(type: ModalType, employee?: IEmployeeUser) {
		setType(type)
		if (type === 'control') setEmployeeProps(employee)
		setModalIsOpen(true)
	}

	function closeModal() {
		setModalIsOpen(false)
		setEmployeeProps(null)
	}

	const ModalComponent = () => {
		switch (type) {
			case 'control':
				return (
					<ChoiceModal
						open={openModal}
						close={closeModal}
						employee={employeeProps}
						role={role}
						update={fetch}
					/>
				)
			case 'edit':
				return (
					<EmployeesModal
						close={closeModal}
						fields={employeeProps}
						update={fetch}
						title="Редактирование"
						type={type}
					/>
				)
			case 'add':
				return (
					<EmployeesModal
						close={closeModal}
						fields={employeeProps}
						update={fetch}
						title="Добавление"
						type={type}
					/>
				)
		}
	}

	if (isLoad) {
		return <Loader />
	}

	return (
		<>
			<Title
				title={shopId ? `Сотрудники магазина ${state.shopName}` : 'Сотрудники'}
			/>
			<div className={styles.wrapper}>
				{role === 'User' ? (
					<>
						<h3>Для получения постоянного пропуска необходимо:</h3>
						<ul className={styles.rules}>
							<li>
								Направить заявку посредством настоящего Портала. В заявке
								указывается номер мобильного телефона сотрудника, на кого
								составляется заявка.
							</li>
							<li>
								После согласования Администрацией ТРК направленная заявка
								получает статус "Принято". С данного момента сотрудник в течение
								срока действия заявки имеет право входить на территорию ТРК
								Гулливер в нерабочие часы без постоянного пропуска, показывая
								сотруднику охраны открытую заявку на сотрудника со статусом
								"Принято". Максимальный срок действия заявки на пропуск - две
								недели. За это время необходимо получить постоянный пропуск!
							</li>
							<li>
								В течение двух рабочих дней с даты принятия заявки сотруднику,
								на кого составлена заявка, необходимо лично подойти на стойку
								информации ТРК (1 этаж) с паспортом для составления согласия на
								обработку персональных данных и фотографирования.
							</li>
							<li>
								Через два рабочих дня с даты фотографирования сотруднику, на
								кого составлена заявка, необходимо лично получить постоянный
								пропуск на стойке информации. После получения пропуска статус
								заявки изменится на "Получен".
							</li>
						</ul>
					</>
				) : null}
				{role === 'User' && (
					<Button
						className={styles.addBtn}
						onClick={() => {
							openModal('add')
						}}
					>
						Добавить
					</Button>
				)}
				{employees?.length ? (
					<div className={styles.table}>
						<Table
							dataProps={employees.map((item) => {
								return {
									...item,
									passageStatus: item.passageStatus ? item.passageStatus : '-',
								}
							})}
							columns={employeesColumns(openModal)}
						/>
					</div>
				) : (
					<SubHeading title="Данных нет" />
				)}
			</div>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				ariaHideApp={false}
				style={modalStyle}
			>
				<ModalComponent />
			</Modal>
		</>
	)
}
