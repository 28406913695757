import { format } from 'date-fns'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'

import { Button, FieldInput, FieldSelect, Textarea, Title } from '@/components'

import { AnnouncementsService } from '@/services'

import { IAnnouncementsAdd, ISelect } from '@/types'

import styles from '../Form.module.scss'

type TAnnouncementsAddFrom = IAnnouncementsAdd & {
	position?: ISelect
}

export const AnnouncementsForm = (): JSX.Element => {
	const navigate = useNavigate()
	const { state } = useLocation()

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm<TAnnouncementsAddFrom>({
		mode: 'onChange',
		defaultValues: {
			text: state?.item?.text,
			startDate: state?.item?.startDateStr
				? format(new Date(state?.item?.startDate), 'yyyy-MM-dd')
				: null,
			endDate: state?.item?.endDateStr
				? format(new Date(state?.item?.endDate), 'yyyy-MM-dd')
				: null,
			position:
				state?.item.position === 'Главная страница'
					? { label: 'Главная страница', value: 1 }
					: state?.item.position === 'Заявка на внос/вынос'
					  ? { label: 'Заявка на внос/вынос', value: 2 }
					  : state?.item.position === 'Заявка на выполнение работ'
					    ? { label: 'Заявка на выполнение работ', value: 3 }
					    : state?.item.position === 'Заявка на промо'
					      ? { label: 'Заявка на промо', value: 4 }
					      : null,
		},
	})

	const onSubmit: SubmitHandler<any> = async (body) => {
		if (state?.item) {
			await AnnouncementsService.edit(
				state?.item?.id,
				{ ...body, position: body.position.value },
				navigate
			)
		} else
			await AnnouncementsService.add(
				{
					text: body.text,
					isActive: new Date() >= new Date(body.startDate) ? true : false,
					startDate: body.startDate,
					endDate: body.endDate,
					position: body.position.value,
				},
				navigate
			)
	}

	return (
		<>
			<Title
				title={state?.item ? 'Редактирование объявления' : 'Новое объявление'}
			/>
			<form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
				<Textarea
					rows={4}
					placeholder="Текст объявления"
					{...register('text', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.text}
				/>
				<FieldInput
					type="date"
					placeholder="Дата начала размещения"
					{...register('startDate', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.startDate}
				/>
				<FieldInput
					type="date"
					placeholder="Дата окончания размещения"
					{...register('endDate', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.endDate}
				/>
				<FieldSelect
					id="position"
					placeholder="Место объявления"
					control={control}
					rules={{
						required: 'Поле обязательно к заполнению!',
					}}
					options={[
						{ label: 'Главная страница', value: 1 },
						{ label: 'Заявка на внос/вынос', value: 2 },
						{ label: 'Заявка на выполнение работ', value: 3 },
						{ label: 'Заявка на промо', value: 4 },
					]}
					error={errors.position}
					required
				/>
				{state?.item ? (
					<Button type="submit">Изменить</Button>
				) : (
					<Button type="submit">Разместить</Button>
				)}
			</form>
		</>
	)
}
