/* eslint-disable @typescript-eslint/no-unused-expressions */
import { toast } from 'react-toastify'

import { axiosPrivate } from '../settings/axios'
import {
	IAddLoadingWithRamp,
	IAddLoadingWithoutRamp,
	IAddPassage,
	IAddPromo,
	IAddWorks,
	IInfo,
} from '../types/Request.type'

const getRequestsUrl = (url: string) => `/requests/${url}`

export const RequestsService = {
	async generateReport(): Promise<any> {
		try {
			axiosPrivate
				.request({
					url: getRequestsUrl(
						`generate_report?IsApproved=true&IsNeedConfirmation=false`
					),
					method: 'GET',
					responseType: 'blob',
				})

				.then(({ data }) => {
					const downloadUrl = window.URL.createObjectURL(new Blob([data]))
					const link = document.createElement('a')
					link.href = downloadUrl
					link.setAttribute(
						'download',
						`${new Date().toLocaleDateString()}.xlsx`
					)
					document.body.appendChild(link)
					link.click()
					link.remove()
				})
		} catch (e: any) {
			console.log(e)

			toast.error(e.message)
			return e.response
		}
	},
	async getAll({
		Identifier,
		CounterpartyName,
		CreationDateFrom,
		CreationDateTo,
		IsNeedConfirmation,
		IsApproved,
		IsArchive,
		RequestTypes,
	}: {
		Identifier?: string
		CounterpartyName?: string
		CreationDateFrom?: string
		CreationDateTo?: string
		IsNeedConfirmation?: boolean
		IsApproved?: boolean
		IsArchive?: boolean
		RequestTypes?: string[]
	}): Promise<IInfo[]> {
		try {
			let params = new URLSearchParams()
			Identifier !== undefined ? params.append('Identifier', Identifier) : null
			CounterpartyName !== undefined
				? params.append('CounterpartyName', CounterpartyName)
				: null
			CreationDateFrom !== undefined
				? params.append('CreationDateFrom', CreationDateFrom)
				: null
			CreationDateTo !== undefined
				? params.append('CreationDateTo', CreationDateTo)
				: null
			IsNeedConfirmation !== undefined
				? params.append('IsNeedConfirmation', String(IsNeedConfirmation))
				: null
			IsApproved !== undefined
				? params.append('IsApproved', String(IsApproved))
				: null
			IsArchive !== undefined
				? params.append('IsArchive', String(IsArchive))
				: null
			RequestTypes !== undefined
				? params.append('RequestTypes', String(RequestTypes))
				: null

			const { data } = await axiosPrivate.get<IInfo[]>(
				getRequestsUrl(`all?${params}`)
			)
			return data
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},
	async getInfo(id: string, error?: boolean) {
		try {
			const { data } = await axiosPrivate.get<IInfo>(
				getRequestsUrl(`get_info?RequestId=${id}`)
			)
			return data
		} catch (e: any) {
			error && toast.error(e.message)
			return e.response
		}
	},
	async status(RequestIdentifier: string) {
		try {
			const { data, status } = await axiosPrivate.get<string>(
				getRequestsUrl(`status?RequestIdentifier=${RequestIdentifier}`)
			)
			toast.info(`Статус заявки: ${data}`)
			return { data, status }
		} catch (e: any) {
			if (e.response.status === 404) {
				toast.error('Заявка с таким номером не найдена')
			} else if (e.response.status === 400)
				toast.error('Неверный формат номера заявки')
			return e.response
		}
	},
	async addLoadingWithRamp(
		body: IAddLoadingWithRamp,
		fun: (url: string) => void,
		role: string,
		CounterpartyId?: string
	): Promise<void> {
		try {
			let params = new URLSearchParams()
			CounterpartyId !== undefined
				? params.append('CounterpartyId', CounterpartyId)
				: null
			await axiosPrivate.post<any>(
				getRequestsUrl(`add/loading_with_ramp?${params}`),
				body
			)
			toast.success('Заявка успешно отправлена')
			fun(role !== 'User' ? '/request/new/list' : '/request/list')
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},
	async editLoadingWithRamp(
		body: IAddLoadingWithRamp & { id: string },
		fun: (url: string) => void,
		role: string
	): Promise<void> {
		try {
			await axiosPrivate.patch<any>(
				getRequestsUrl('edit/loading_with_ramp'),
				body
			)
			toast.success('Заявка успешно изменена')
			fun(role !== 'User' ? '/request/new/list' : '/request/list')
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},
	async addLoadingWithoutRamp(
		body: IAddLoadingWithoutRamp,
		fun: (url: string) => void,
		role: string,
		CounterpartyId?: string
	): Promise<void> {
		try {
			let params = new URLSearchParams()
			CounterpartyId !== undefined
				? params.append('CounterpartyId', CounterpartyId)
				: null
			await axiosPrivate.post<any>(
				getRequestsUrl(`add/loading_without_ramp?${params}`),
				body
			)
			toast.success('Заявка успешно отправлена')
			fun(role !== 'User' ? '/request/new/list' : '/request/list')
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},
	async editLoadingWithoutRamp(
		body: IAddLoadingWithoutRamp & { id: string },
		fun: (url: string) => void,
		role: string
	): Promise<void> {
		try {
			await axiosPrivate.patch<any>(
				getRequestsUrl('edit/loading_without_ramp'),
				body
			)
			toast.success('Заявка успешно изменена')
			fun(role !== 'User' ? '/request/new/list' : '/request/list')
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},
	async addWorks(
		body: IAddWorks,
		fun: (url: string) => void,
		role: string,
		CounterpartyId?: string
	): Promise<void> {
		try {
			let params = new URLSearchParams()
			CounterpartyId !== undefined
				? params.append('CounterpartyId', CounterpartyId)
				: null
			await axiosPrivate.post<any>(getRequestsUrl(`add/works?${params}`), body)
			toast.success('Заявка успешно отправлена')
			fun(role !== 'User' ? '/request/new/list' : '/request/list')
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},
	async editWorks(
		body: IAddWorks & { id: string },
		fun: (url: string) => void,
		role: string
	): Promise<void> {
		try {
			await axiosPrivate.patch<any>(getRequestsUrl('edit/works'), body)
			toast.success('Заявка успешно изменена')
			fun(role !== 'User' ? '/request/new/list' : '/request/list')
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},
	async addPromo(
		body: IAddPromo,
		fun: (url: string) => void,
		role: string,
		CounterpartyId?: string
	): Promise<void> {
		try {
			let params = new URLSearchParams()
			CounterpartyId !== undefined
				? params.append('CounterpartyId', CounterpartyId)
				: null
			await axiosPrivate.post<any>(getRequestsUrl(`add/promo?${params}`), body)
			toast.success('Заявка успешно отправлена')
			fun(role !== 'User' ? '/request/new/list' : '/request/list')
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},
	async editPromo(
		body: IAddPromo & { id: string },
		fun: (url: string) => void,
		role: string
	): Promise<void> {
		try {
			await axiosPrivate.patch<any>(getRequestsUrl('edit/promo'), body)
			toast.success('Заявка успешно изменена')
			fun(role !== 'User' ? '/request/new/list' : '/request/list')
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},
	async addPassage(
		body: IAddPassage,
		fun: (url: string) => void,
		role: string,
		CounterpartyId?: string
	): Promise<void> {
		try {
			let params = new URLSearchParams()
			CounterpartyId !== undefined
				? params.append('CounterpartyId', CounterpartyId)
				: null
			await axiosPrivate.post<any>(
				getRequestsUrl(`add/passage?${params}`),
				body
			)
			toast.success('Заявка успешно отправлена')
			fun(role !== 'User' ? '/request/new/list' : '/request/list')
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},
	async editPassage(
		body: IAddPassage & { id: string },
		fun: (url: string) => void,
		role: string
	): Promise<void> {
		try {
			await axiosPrivate.patch<any>(getRequestsUrl('edit/passage'), body)
			toast.success('Заявка успешно изменена')
			fun(role !== 'User' ? '/request/new/list' : '/request/list')
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},
	async cancel(id: string, close: () => void): Promise<void> {
		try {
			await axiosPrivate.patch<any>(getRequestsUrl('cancel'), id, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			toast.success('Заявка аннулирована')
			close()
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},
	async approve(id: string, close: () => void): Promise<void> {
		try {
			await axiosPrivate.patch<any>(getRequestsUrl('approve'), id, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			toast.success('Статус заявки изменен')
			close()
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},
	async decline(id: string, close: () => void): Promise<void> {
		try {
			await axiosPrivate.patch<any>(getRequestsUrl('decline'), id, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			toast.success('Статус заявки изменен')
			close()
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},
	async setComments(
		id: string,
		body: {
			renterComment: string
			guardComment: string
		},
		close: () => void
	): Promise<number> {
		try {
			await axiosPrivate.patch<any>(
				getRequestsUrl(`set_comments?RequestId=${id}`),
				body
			)
			toast.success('Комментарий добавлен')
			close()
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},
}
