/* eslint-disable @typescript-eslint/no-unused-expressions */
import { toast } from 'react-toastify'

import { axiosPrivate } from '../settings/axios'
import { IAnnouncements, IAnnouncementsAdd } from '../types/Announcements.type'

const getAnnouncementsUrl = (url: string) => `/announcements/${url}`

export const AnnouncementsService = {
	async getAll(IsActive?: boolean): Promise<IAnnouncements[]> {
		let params = new URLSearchParams()
		IsActive !== undefined ? params.append('IsActive', String(IsActive)) : null

		try {
			const { data } = await axiosPrivate.get<IAnnouncements[]>(
				getAnnouncementsUrl(`all?${params}`)
			)
			return data
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async add(body: IAnnouncementsAdd, nav: (path: string) => void) {
		try {
			await axiosPrivate.post<any>(getAnnouncementsUrl('add'), body)
			toast.success('Новое объявление добавлено')
			nav('/announcement/list')
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async edit(id: string, body: IAnnouncementsAdd, nav: (path: string) => void) {
		try {
			await axiosPrivate.patch<any>(
				getAnnouncementsUrl(`edit?AnnouncementId=${id}`),
				{
					...body,
					isActive: new Date(body.endDate) > new Date() ? true : false,
				}
			)
			toast.success('Объявление изменено')
			nav('/announcement/list')
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async switchIsActive(id: string, update: () => void, close: () => void) {
		try {
			await axiosPrivate.patch(
				`/announcement/switch_is_active?AnnouncementId=${id}`
			)
			toast.success('Статус изменен')
			update()
			close()
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async delete(id: string, update: () => void, close: () => void) {
		try {
			await axiosPrivate.delete<any>(
				getAnnouncementsUrl(`delete?AnnouncementId=${id}`)
			)
			toast.success('Объявление удалено')
			update()
			close()
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},
}
