import { useEffect, useState } from 'react'
import Modal from 'react-modal'

import { Loader, Table, Title } from '@/components'

import { AnnouncementsService } from '@/services'

import { modalStyle } from '@/settings'

import { IAnnouncements } from '@/types'

import styles from './List.module.scss'
import { ChoiceModal } from './Modal'
import { columns } from './table.config'

export const AnnouncementsList = (): JSX.Element => {
	const [advertisements, setAdvertisements] = useState<IAnnouncements[]>([])
	const [isLoad, setIsLoad] = useState<boolean>(true)
	const [modalIsOpen, setIsOpen] = useState<boolean>(false)
	const [announcementProps, setAnnouncementProps] = useState<IAnnouncements>()

	useEffect(() => {
		fetch()
	}, [])

	const fetch = async () => {
		setIsLoad(true)
		const data = await AnnouncementsService.getAll()

		setAdvertisements(data)
		setIsLoad(false)
	}

	const openModal = (announcement: IAnnouncements) => {
		setAnnouncementProps(announcement)
		setIsOpen(true)
	}

	const closeModal = () => {
		setIsOpen(false)
	}

	if (isLoad) {
		return <Loader />
	}

	return (
		<>
			<div className={styles.wrapper}>
				<Title title="Список объявлений" />
				<div className={styles.table}>
					<Table
						dataProps={advertisements
							.sort((a) => {
								if (a.isActive) {
									return -1
								} else {
									return 1
								}
							})
							.map((item) => {
								return {
									...item,
									position:
										item.position === 1
											? 'Главная страница'
											: item.position === 2
											  ? 'Заявка на внос/вынос'
											  : item.position === 3
											    ? 'Заявка на выполнение работ'
											    : item.position === 4
											      ? 'Заявка на промо'
											      : '-',
									isActiveStr: item.isActive ? 'Активный' : 'Не активный',
									startDateStr: new Date(item.startDate).toLocaleDateString(),
									endDateStr: new Date(item.endDate).toLocaleDateString(),
								}
							})}
						columns={columns(openModal)}
					/>
				</div>
			</div>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				ariaHideApp={false}
				style={modalStyle}
			>
				<ChoiceModal
					close={closeModal}
					announcement={announcementProps}
					update={fetch}
				/>
			</Modal>
		</>
	)
}
