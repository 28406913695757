import Cookies from 'js-cookie'
import { nanoid } from 'nanoid'
import { NotFound } from 'pages/NotFound/NotFound'
import { Route, Routes } from 'react-router-dom'

import { withLayout } from './Layout/Layout'
import { useAuth } from './hooks/useAuth'
import { routes } from './routes.data'

function App() {
	const { role, setRole, setToken, serRefreshToken } = useAuth()

	const tokenCookies = Cookies.get('token')
	const refreshToken = Cookies.get('refreshToken')
	const roleCookies = Cookies.get('role')

	if (tokenCookies !== undefined) setToken && setToken(tokenCookies)
	if (refreshToken !== undefined)
		serRefreshToken && serRefreshToken(refreshToken)
	if (roleCookies !== undefined) setRole && setRole(roleCookies)

	return (
		<Routes>
			{routes
				.reduce((arr: any, item) => {
					let d =
						item.role.filter((rl) => rl === role)[0] === role
							? arr.push(item)
							: null
					return arr
				}, [])
				.map((route: any) => (
					<Route path={route.path} element={<route.element />} key={nanoid()} />
				))}
			<Route path="*" element={<NotFound />} />
		</Routes>
	)
}

export default withLayout(App)
