import cn from 'clsx'
import { FC, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { MdClose } from 'react-icons/md'
import Select from 'react-select'

import { Button, FieldInput, SubHeading } from '@/components'

import { UserService } from '@/services'

import { selectMyStyles } from '@/settings'

import styles from './Modal.module.scss'

interface IFrom {
	type: string
	email: string
	surname: string
	name: string
	lastName: string
	password: string
	phoneNumber: string
	cpName?: string
	cpLegalName?: string
	cpITN?: string
}

export const AddUserModal: FC<{
	close: () => void
}> = ({ close }) => {
	const [type, setType] = useState<string>('')

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm<IFrom>({
		mode: 'onChange',
	})

	const onSubmit: SubmitHandler<IFrom> = async (body) => {
		switch (type) {
			case 'User':
				await UserService.createUser(
					{
						email: body.email,
						userName: `${body.surname} ${body.name} ${body.lastName}`,
						password: body.password,
						phoneNumber: body.phoneNumber,
						cpName: body.cpName,
						cpLegalName: body.cpLegalName,
						cpITN: body.cpITN,
					},
					close
				)
				break
			case 'Manager':
				await UserService.createManager(
					{
						...body,
						userName: `${body.surname} ${body.name} ${body.lastName}`,
					},
					close
				)
				break
			case 'Marketer':
				await UserService.createMarketer(
					{
						...body,
						userName: `${body.surname} ${body.name} ${body.lastName}`,
					},
					close
				)
				break
			case 'Guard':
				await UserService.createGuard(
					{
						...body,
						userName: `${body.surname} ${body.name} ${body.lastName}`,
					},
					close
				)
				break
			case 'Dispatcher':
				await UserService.createDispatcher(
					{
						...body,
						userName: `${body.surname} ${body.name} ${body.lastName}`,
					},
					close
				)
				break
			default:
				break
		}
	}

	return (
		<div className={cn(styles.modal, 'min-h-[300px]')}>
			<div className={styles.header}>
				<SubHeading title="Добавить пользователя" />
				<MdClose size={20} onClick={close} />
			</div>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={cn(styles.body, styles.form)}
			>
				<div className={styles.select}>
					<label htmlFor="type">
						<span>Выберите тип пользователя</span>
						<Select
							instanceId="type"
							inputId="type"
							onChange={(value: any) => {
								setType(value.value)
								reset()
							}}
							options={[
								{ label: 'Юридическое лицо', value: 'User' },
								{ label: 'Менеджер', value: 'Manager' },
								{ label: 'Маркетолог', value: 'Marketer' },
								{ label: 'Охранник', value: 'Guard' },
								{ label: 'Диспетчер', value: 'Dispatcher' },
							]}
							styles={selectMyStyles}
							placeholder="Выберите тип пользователя"
						/>
					</label>
				</div>
				{type === 'User' ? (
					<>
						<FieldInput
							type="email"
							placeholder="Email"
							{...register('email', {
								required: 'Поле обязательно к заполнению!',
							})}
							error={errors.email}
							required
						/>
						<FieldInput
							placeholder="Фамилия"
							{...register('surname', {
								required: 'Поле обязательно к заполнению!',
							})}
							error={errors.surname}
							required
						/>
						<FieldInput
							placeholder="Имя"
							{...register('name', {
								required: 'Поле обязательно к заполнению!',
							})}
							error={errors.name}
							required
						/>
						<FieldInput
							placeholder="Отчество"
							{...register('lastName', {
								required: 'Поле обязательно к заполнению!',
							})}
							error={errors.lastName}
							required
						/>
						<FieldInput
							type="password"
							placeholder="Пароль"
							{...register('password', {
								required: 'Поле обязательно к заполнению!',
								pattern: {
									value:
										/(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{7,}/g, //eslint-disable-line
									message:
										'Пароль должен быть длиннее 7 символов, должен содержать хотя бы одну цифру, должен иметь хотя бы одну заглавную букву',
								},
							})}
							error={errors.password}
							required
						/>
						<FieldInput
							type="tel"
							placeholder="Телефон"
							{...register('phoneNumber', {
								required: 'Поле обязательно к заполнению!',
								pattern: {
									value: /(\+7|7|8)+([0-9]){10}/gm, //eslint-disable-line
									message: 'Формат номера телефона +79123456789',
								},
							})}
							error={errors.phoneNumber}
							required
						/>
						<FieldInput
							type="text"
							placeholder="Название организации"
							{...register('cpName', {
								required: 'Поле обязательно к заполнению!',
							})}
							error={errors.cpName}
							required
						/>
						<FieldInput
							type="text"
							placeholder="Юридическое лицо"
							{...register('cpLegalName', {
								required: 'Поле обязательно к заполнению!',
							})}
							error={errors.cpLegalName}
							required
						/>
						<FieldInput
							type="number"
							placeholder="ИНН"
							{...register('cpITN', {
								required: 'Поле обязательно к заполнению!',
							})}
							error={errors.cpITN}
							required
						/>
						<Button className="mx-auto" type="submit">
							Создать
						</Button>
					</>
				) : type !== '' ? (
					<>
						<FieldInput
							type="email"
							placeholder="Email"
							{...register('email', {
								required: 'Поле обязательно к заполнению!',
							})}
							error={errors.email}
							required
						/>
						<FieldInput
							placeholder="Фамилия"
							{...register('surname', {
								required: 'Поле обязательно к заполнению!',
							})}
							error={errors.surname}
							required
						/>
						<FieldInput
							placeholder="Имя"
							{...register('name', {
								required: 'Поле обязательно к заполнению!',
							})}
							error={errors.name}
							required
						/>
						<FieldInput
							placeholder="Отчество"
							{...register('lastName', {
								required: 'Поле обязательно к заполнению!',
							})}
							error={errors.lastName}
							required
						/>
						<FieldInput
							type="password"
							placeholder="Пароль"
							{...register('password', {
								required: 'Поле обязательно к заполнению!',
								pattern: {
									value:
										/(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{7,}/g, //eslint-disable-line
									message:
										'Пароль должен быть длиннее 7 символов, должен содержать хотя бы одну цифру, должен иметь хотя бы одну заглавную букву',
								},
							})}
							error={errors.password}
							required
						/>
						<FieldInput
							type="tel"
							placeholder="Телефон"
							{...register('phoneNumber', {
								required: 'Поле обязательно к заполнению!',
								pattern: {
									value: /(\+7|7|8)+([0-9]){10}/gm, //eslint-disable-line
									message: 'Формат номера телефона +79123456789',
								},
							})}
							error={errors.phoneNumber}
							required
						/>
						<Button className="mx-auto" type="submit">
							Создать
						</Button>
					</>
				) : null}
			</form>
		</div>
	)
}
